import React, { Component } from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import PropTypes from 'prop-types'
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core'


class ExpansionPanelDiagnosis extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isContentHidden: true,
    }
  }
  render() {
    const content = this.props.content
    const isMobile = this.props.isMobile
    return (
      <div>
        {isMobile ? (
          <div>
            {content.map((item, index) => (
              <ExpansionPanel key={index} className="expansion-container">
                <ExpansionPanelSummary aria-controls="panel1a-content" expandIcon={<ExpandMoreIcon />}>
                  <div key={index} className="section-content-wrapper">
                    <div className="icon-line-container">
                      <div className="icon-container">
                        <img src={item.icon} className={item.iconClass} alt={item.iconAlt} />
                      </div>
                    </div>
                    {item.header}
                  </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className="link-group">{item.textOne}</ExpansionPanelDetails>
              </ExpansionPanel>
            ))}
          </div>
        ) : (
          <div className="lg-sec">
            {content.map((item, index) => (
              <div key={index} className="section-content-wrapper">
                <div className="icon-line-container">
                  <div className="icon-container">
                    <img src={item.icon} className={item.iconClass} alt={item.iconAlt} />
                  </div>
                  {!item.line ? '' : <div className="vl" />}
                </div>
                <div className="text-container">
                  {item.header}
                  {item.textOne}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    )
  }
}

ExpansionPanelDiagnosis.propTypes = {
  isMobile: PropTypes.bool,
  content: PropTypes.any,
}

export default ExpansionPanelDiagnosis
