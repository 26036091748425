import React, { useState, useEffect } from 'react'

import Layout from '@components/layout/Layout'
import LearnMoreComponent from '@components/learn-more/learn-more.component'
import ReferenceComponent from '@components/references/reference.component'
import BrightcoveVideo from '@components/brightcove-video/brightcove-video.component'
import { videos } from '@components/video-library/videos'
import { references } from '@components/references/diagnostic-process-references'
import ReadNext from '@components/read-next-button/read-next-button'
import ExpansionPanelDiagnosis from '@components/expansion-panel-diagnosis'

import interview from '@assets/icons/clinical-interview-icon.png'
import laboratory from '@assets/icons/sleep-laboratory-testing.png'
import hypocretin from '@assets/icons/graph-icon.png'
import imgDiagnosticProcessThumbnail from '@assets/images/thumbnails/narcolepsy-patient-perspectives-diagnosis-relief-video.jpeg'

import './diagnostic-process.scss'

const DiagnosticProcess = ({ location }) => {
  const [screenWidth, setScreenWidth] = useState()

  const handleWindowResize = () => {
    setScreenWidth(window.innerWidth)
  }
  useEffect(() => {
    setScreenWidth(window.innerWidth)
    window.addEventListener('resize', handleWindowResize)
    return (
        window.removeEventListener('resize', handleWindowResize)
    )
  },[])

  const learnMoreContent = {
    className: 'learn-more-wrapper-gray',
    title: 'Narcolepsy has 5 main symptoms',
    supText: '2',
    link: '/screening-and-diagnosis/narcolepsy-differential-diagnosis/',
    linkLabel: 'Are you familiar with all 5?',
    tracking: 'diagnostic process,	click, diagnostic challenges cta - diagnostic process ',
  }

  const readNext = {
    readNextContent: 'Diagnostic challenges with narcolepsy',
    goTo: '/screening-and-diagnosis/narcolepsy-differential-diagnosis/',
    tracking: 'diagnostic process, click, diagnostic challenges - diagnostic process',
  }

  const groupContents = [
    {
      icon: interview,
      header: <h2 className="tertiary-header">Clinical Interview</h2>,
      textOne: (
        <div className="body-copy">
          A complete clinical interview, including assessment for all 5 narcolepsy symptoms (CHESS:&nbsp;
          <b>C</b>ataplexy, <b>H</b>allucinations, <b>E</b>xcessive daytime sleepiness, <b>S</b>leep paralysis, <b>S</b>
          leep disruption), is important to the differential diagnosis and provides clinical context when evaluating the
          results of sleep laboratory testing.
          <sup>1-4</sup> Including the patient&apos;s spouse, bed partner, or another family member during the clinical
          interview can be helpful to corroborate or refute the patient’s report of sleepiness or to uncover cataplexy.
          <sup>5,6</sup>
        </div>
      ),
      iconClass: 'icon-interview',
      iconAlt: 'Icon of 2 people talking side by side',
      line: true,
    },
    {
      icon: laboratory,
      header: <h2 className="tertiary-header">Sleep Laboratory Testing</h2>,
      textOne: (
        <div className="body-copy">
          Sleep laboratory testing, consisting of overnight polysomnography (PSG) followed by a multiple sleep latency
          test (MSLT), should be performed if narcolepsy is suspected.<sup>1</sup> Sleep laboratory testing should be
          performed under appropriate conditions using standardized techniques and should be interpreted within the
          clinical context of the patient’s history and symptoms.<sup>1</sup>
          <ul className="padding-align-left">
            <li>
              <span>
                Home Sleep Apnea Testing (HSAT) cannot detect narcolepsy and is not recommended if narcolepsy is
                suspected
                <sup>7,8</sup>
              </span>
            </li>
          </ul>
        </div>
      ),
      iconClass: 'icon-laboratory',
      iconAlt: 'Icon of bed side view Zz letters',
      line: true,
    },
    {
      icon: hypocretin,
      header: <h2 className="tertiary-header">Hypocretin-1 Levels</h2>,
      textOne: (
        <div className="body-copy">
          Assessment of cerebrospinal fluid (CSF) hypocretin-1 levels is another objective test that can confirm a
          narcolepsy type 1 diagnosis.<sup>1</sup> However, CSF hypocretin-1 levels typically are not obtained in
          clinical practice.<sup>9</sup> An assay is now commercially available.
          <div className="spacer-15" />
          Hypocretin deficiency in patients with excessive daytime sleepiness is considered diagnostic for narcolepsy
          type 1 in the ICSD-3<sup>1</sup> and for narcolepsy in the DSM‑5.<sup>1,10</sup> Hypocretin deficiency is generally defined
          as CSF hypocretin-1 levels ≤110 pg/mL or &lt;1/3 (≤1/3 in DSM‑5) of mean values obtained using the same assay
          in healthy subjects.<sup>1,10</sup>
        </div>
      ),
      iconClass: 'Icon of downward arrow in graph',
      iconAlt: 'Hypocretin-1 Levels',
      line: false,
    },
  ]

  const { destinationDiagnosis } = videos
  return (
    <Layout addedClass='page-diagnostic-process' location={location}>
      <div className="making-diagnosis-page-wrapper">
        <div className="making-diagnosis-page-header">
          <div className="primary-container">
            <div className="text-content-wrapper">
              <h1 className="main-page-header">Making the Narcolepsy Diagnosis</h1>
              <p className="body-copy-large">
                Diagnosis of patients with suspected narcolepsy usually starts with the identification of excessive
                daytime sleepiness (EDS), or periods of an irrepressible need to sleep during the day. However, the
                establishment of EDS alone is not sufficient to make the diagnosis. Additional interviewing and testing
                are necessary.<sup>1</sup>
              </p>
            </div>
          </div>
          <div className="image-container" role="image" aria-label="Diagnostic process for narcolepsy" />
        </div>
        <div className="white-background">
          <section className="section-gradient" />
        </div>
        <div className="primary-container">
          <div className="section-header">
            <div className="learn-more-wrapper">
              <LearnMoreComponent learnMoreContent={learnMoreContent} />
            </div>
            <div className="section-header-wrapper">
              <h2 className="section-header diagnostic">The Diagnostic Process</h2>
            </div>
          </div>
          <ExpansionPanelDiagnosis isMobile={screenWidth < 750} content={groupContents} />
        </div>
        <div className="video-background">
          <div className="primary-container">
            <div className="section-header-wrapper">
              <h2 className="section-header video-header">A Patient’s Perspective</h2>
              <p>
                Watching patients describe the relief they felt when the diagnosis was made can help you discuss,
                recognize symptoms, and understand your patient’s life-long journey.
              </p>
            </div>
            <div className="video-wrapper">
              <div className="video-content">
                <div className="video"> 
                  <BrightcoveVideo
                    player={destinationDiagnosis.player}
                    account={destinationDiagnosis.account}
                    videoId={destinationDiagnosis.videoId}
                    tracking={destinationDiagnosis.tracking}
                    trackPercentComplete={destinationDiagnosis.trackPercentComplete}
                    src={imgDiagnosticProcessThumbnail}
                  />
                </div>
              </div>
            </div>
            <div className="margin-top-30" />
            <ReadNext readNext={readNext} />
          </div>
        </div>
        <ReferenceComponent referenceList={references} />
      </div>
    </Layout>
  )
}

export default DiagnosticProcess
