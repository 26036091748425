export const references = [
  {
    content: 'American Academy of Sleep Medicine. Central disorders of hypersomnolence.',
    source:
      ' In: The International Classification of Sleep Disorders – Third Edition (ICSD-3) Online Version. ',
    info: 'Darien, IL: American Academy of Sleep Medicine; 2014.',
  },
  {
    content: 'Pelayo R, Lopes MC. Narcolepsy. In: Lee-Chiong TL, ed.',
    source: 'Sleep: A Comprehensive Handbook. ',
    info: 'Hoboken, NJ: Wiley and Sons, Inc.; 2006:145-149.',
  },
  {
    content: 'Thorpy MJ, Krieger AC. Delayed diagnosis of narcolepsy: characterization and impact. ',
    source: 'Sleep Med. ',
    info: '2014;15(5):502-507.',
  },
  {
    content:
      'Littner MR, Kushida C, Wise M, et al.; Standards of Practice Committee of the American Academy of Sleep Medicine. Practice parameters for clinical use of the multiple sleep latency test and the maintenance of wakefulness test. ',
    source: 'Sleep. ',
    info: '2005;28(1):113-121.',
  },
  {
    content:
      'Ahmed IM, Thorpy MJ. Clinical evaluation of the patient with excessive sleepiness. In: Thorpy MJ, Billiard M, eds.',
    source: 'Sleepiness: Causes, Consequences and Treatment. ',
    info: 'Cambridge, United Kingdom: Cambridge University Press; 2011:36-49.',
  },
  {
    content: 'Guilleminault C, Brooks SN. Excessive daytime sleepiness: a challenge for the practicing neurologist. ',
    source: 'Brain. ',
    info: '2001;124(pt 8):1482-1491.',
  },
  {
    content:
      'Collop NA, Anderson WM, Boehlecke B, et al.; Portable Monitoring Task Force of the American Academy of Sleep Medicine. Clinical guidelines for the use of unattended portable monitors in the diagnosis of obstructive sleep apnea in adult patients. Portable Monitoring Task Force of the American Academy of Sleep Medicine. ',
    source: 'J Clin Sleep Med. ',
    info: '2007;3(7):737-747.',
  },
  {
    content:
      'Kapur VK, Auckley DH, Chowdhuri S, et al. Clinical practice guideline for diagnostic testing for adult obstructive sleep apnea: an American Academy of Sleep Medicine clinical practice guideline.',
    source: 'J Clin Sleep Med. ',
    info: '2017;13(3):479-504.',
  },
  {
    content:
      'Stanford Center for Narcolepsy. Narcolepsy Research - FAQs. Available at: http://med.stanford.edu/narcolepsy/faq1.html#3 . Accessed June 28, 2018.',
    source: '',
    info: '',
  },
  {
    content:
      'American Psychiatric Association. ',
    source: 'Diagnostic and Statistical Manual of Mental Disorders, Fifth Edition (DSM-5). ',
    info: 'Arlington, VA: American Psychiatric Association; 2013.',
  },
]